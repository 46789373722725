@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0 0.3rem 0.3rem 0.3rem;
  /* background-color: #F7FAFF; */
  background-color: #ffffff;
}


.scndForm {
  width: 45%;
  margin-top: 4rem;
}

.btn:focus, .btn:active {
  box-shadow: none;
  background-color: #fb2300 !important;
}

/* .form-select{
  background-image: url("https://alphanumericideas.com/images/careers/icons/accounts.png") !important;
} */

.navField:focus, .navField:active {
  outline: none !important;
  box-shadow: none;
  border: 1px solid #000222 !important;
}

.navColor {
  background-color: #ffffff;
  margin-bottom: 0.3rem;
}

.whiteBG {
  background-color: white;
}

.headImg {
  padding-top: 4rem;
}

.ftimg {
  position: absolute;
  /* bottom: 20vh; */
  z-index: -1;
  padding: 0 !important;
}

.ftimg1 {
  width: 97.6vw;

  position: relative;
  bottom: 110px;
  z-index: -1;
}

.ftImgStrip1 {
  margin-top: -8.5rem;
  display: flex;
  justify-content: center
}

.heigh {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Lheight {
  height: 5rem;
}

.signBox {
  padding: 1rem 1rem 2.5rem !important;
  margin: auto;
  background-color: #ffffff;
  height: 25rem;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  box-shadow: inset 0px -12px 8px -10px #4b4b4b;
}

.signText {
  font-size: 3.4rem;
  letter-spacing: -1px;
  line-height: 92px;
  color: #000000;
  font-weight: bold;
}

.signBtn {
  font-size: 2.5rem;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 1rem 9rem;
  border: none;
  border-radius: 13px;
  background-color: #fe7528;
  margin-top: 3rem;
}

.ftImgStrip {
  margin-top: 7.4rem;
  display: flex;
  justify-content: center;
}

.gLogo {
  border-radius: 50%;
  margin-right: 1rem;
}

.signBtn:hover {
  background-color: #ec6b21;
}

.headText {
  margin: 1.5rem;
  margin-left: 5rem;
  font-size: 2rem;
  align-self: center;
  letter-spacing: -1px;
  line-height: 54px;
  color: #272727;
  font-weight: 600;
  font-family: "Product Sans";
}

.txtColor {
  color: #fb2300;
}

.hdrBtn {
  margin-top: 6rem;
  background-color: #fb2300;
  border-radius: 10rem;
  border: none;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 29px;
  padding: 0.6rem 1.9rem;
}

.hdrBtn:hover {
  background-color: #ca1b00;
}

.hdrBtn:active {
  background-color: #b72109 !important;
}

.strip {
  background-color: #000222;
  color: white;
}

.strip1 {
  color: #000000;
}

.strip, .strip1 {
  font-size: 0.9rem;
}

.logoStyle {
  margin: 0.8rem 2rem;
  max-width: 60%;
  height: auto;
  cursor: pointer !important;
}

.navButton {
  background-color: #fb2300;
  border-radius: 26px;
  padding: 0.5rem 1.3rem !important;
  margin-top: 0 !important;
  text-transform: uppercase;
  color: #ffffff !important;
  font-size: 16px;
  font-weight: 500;
  border: none !important;
}

.navButton:hover {
  background-color: #ca1b00;
}

.navButton:focus, .navButton:active {
  outline: none !important;
  box-shadow: none;
  background-color: #ca1b00;
}

.congImg {
  margin: 2rem;
}

.congText {
  color: #f42301;
  font-size: 2.3rem;
  font-weight: 700;
}
.congText2 {
  font-size: 1.5rem;
  color: #6d6b6b;
  font-weight: 700;
}

.congBtn{
  padding: 1.3rem;
  border-radius: 50%;
  background-color: #737373;
  border: none;
}

.linkStyle {
  text-decoration: none;
  color: #000000;
  background-color: #F7FAFF;
  border-radius: 26px;
  border: 1px solid black;
  text-align: center;
  transition: all 0.3s;
}

.linkStyle:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  /* background-color: #da0606; */
  color: black;
  border: 1px solid rgb(184, 17, 17);
}

.linkStyle:focus {
  color: #000000;
}

.main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.btnStyle {
  background-color: red;
  color: white !important;
  border-color: white;
}

.quizStyle {
  padding: 2rem 0 3rem 6rem;
  margin: 0.3rem 0 0 0;
  box-shadow: inset 0px -16px 8px -10px #CCC;
}

.ansStyle {
  width: 25rem;
  padding-top: 2rem;
}

input[type="radio"] {
  cursor: pointer;
}

.QuizBtn {
  text-align: center;
}

.timeStyle {
  line-height: 4rem;
  color: #000222;
  margin-right: 3rem;
}

.txt {
  text-align: right;
  padding: 3rem 6rem 0rem 0rem;
}

.resumeLink {
  text-decoration: none;
  color: rgb(125, 182, 231);
}

.resumeLink:hover {
  color: rgb(88, 149, 203);
}

.accepted {
  color: rgba(36, 170, 36, 0.986);
}

.rejected {
  color: rgb(254, 85, 69);
}

.timestl {
  background-color: #fdfdfd;
  top: 3.9rem;
  text-align: center;
  padding: 0.5rem;
}

.xlsButton {
  padding: 0 0 0.8rem;
  border: none;
  background-color: white;
  transition: all 0.1s 0.1s;
  cursor: pointer;
}

.xlsButton:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background-color: white;
}

.xlsButton:visited {
  background-color: white !important;
}

.xls {
  width: 3rem;
  height: 2.7rem;
}

.text {
  text-align: center;
}

.thankyouImg {
  height: 50vh;
}

.btnStyle:disabled {
  background-color: #535a6a;
  border: none;
}

.footerMargin {
  margin-top: 15rem;
}

input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.scheduleError {
  color: rgb(220, 30, 30);
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}

.googlelogin {
  background-color: rgb(198, 7, 7) !important;
  color: #fdfdfd !important;
  margin-top: 10rem;
}

.lsLink {
  margin-right: 15rem;
}

.navFieldDiv {
  position: fixed;
  z-index: 40000;
  top: 1.4rem;
  right: 9rem;
  width: 60vw;
  padding: .4rem;
  display: flex;
  justify-content: end;
}

.navField {
  color: #000000;
  border-radius: 26px;
  border: 1px solid rgb(0, 0, 0);
  cursor: pointer;
  text-align: center;
  width: 8.2rem;
  margin-right: 0.5rem;
  padding: .4rem 2rem .4rem .8rem;
}

.registaerHeading {
  font-size: 2.4rem;
  letter-spacing: -1px;
  line-height: 54px;
  color: #000222;
  font-weight: 300;
  text-align: center;
  margin-bottom: 1rem;
}

/* .formDiv {
  width: 63%;
} */

.nameText {
  font-size: 2.4rem;
  letter-spacing: -1px;
  line-height: 54px;
  color: #000222;
  font-weight: 300;
  margin-bottom: 1rem;
}

.registerSmallHeading {
  font-size: 0.9rem;
  letter-spacing: 0px;
  line-height: 20px;
  color: #000222;
  margin-bottom: 2.5rem;
}

.form-control, .form-select {
  padding: 0.5rem 0.9rem !important;
  border-radius: 14px !important;
}

.labelText {
  font-size: 1.2rem;
  letter-spacing: 0px;
  color: #000222;
  font-weight: 300;
}

.registerBtn {
  border-radius: 14px;
  background-color: #fb2300;
  color: #000000;
  padding: 0.6rem 2rem;
  border: none;
  font-size: 1.1rem;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 500;
  border-radius: 26px;
}

.registerBtn:hover {
  color: #ffffff;
  background-color: #be260e;
}

.heading {
  font-size: 1.5rem;
  padding: 2rem 0 2rem 6rem;
  color: #313131;
  margin-top: 3rem;
}

.card-body {
  padding: 0px !important;
}

.cardImage {
  height: 250px;
}

.cardStyle {
  border: none;
  margin: 1rem;
  transition: all 0.4s;
  box-shadow: 0 0 20px 9px rgb(236, 234, 234);
}

.card-title {
  margin-bottom: 0.5rem;
  padding: 0 1rem;
}

.card-text {
  padding: 0.4rem 1rem;
  margin-bottom: 0;
}

.cardSt {
  border: none;
  cursor: pointer;
}

.cardStyle:hover {
  box-shadow: none;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
}

.jobSelect {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.readMore2 {
  cursor: pointer;
  color: #fb2300;
  transition: all 0.2s ease-in-out;
  display: inline-block;
}

.readMore3 {
  transition: all 0.2s ease-in-out;
  opacity: 0;
  display: inline-block;
}

.readMore {
  cursor: pointer;
  color: #fb2300;
  font-weight: 900;
}

.readMore:hover .readMore2 {
  -webkit-transform: translateX(20px);
          transform: translateX(20px);
  opacity: 0;
}

.readMore:hover .readMore3 {
  opacity: 1;
  -webkit-transform: translateX(20px);
          transform: translateX(20px);
}

.quizDesHeading {
  font-size: 2rem;
  color: #000000;
  font-weight: 500;
}

.building {
  margin-top: 2rem;
}

.location {
  margin-bottom: 3.5rem;
  margin-top: 2.4rem;
  margin-right: 1.8rem;
}

.quizSpan {
  font-size: 1.2rem;
  margin-top: 2rem !important;
  color: #000000;
  display: inline-block;
}

.quizSecondHeading {
  font-size: 1.3rem;
  letter-spacing: 0px;
  line-height: 82px;
  color: #000000;
  font-weight: bold;
}

.minQuali {
  font-size: 1rem;
  line-height: 40px;
  color: #000000;
}

.quizIns {
  font-size: 1rem;
  line-height: 38px;
  color: #000000;
}

.aboutJob {
  font-size: 1rem;
  line-height: 50px;
  color: #000000;
}

.quizButton {
  border-radius: 6px;
  background-color: #fb2300;
  border: none;
  text-transform: uppercase;
  border-radius: 21px;
  padding: 0.6rem 1rem;
  font-size: 15px;
}

.quizButton:hover {
  background-color: #ca1b00;
}

.quesStlyle {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2rem;
}

.qResult {
  border: 1px solid #bebcbc;
  border-radius: 1rem;
  padding: 0.3rem 4rem;
  margin: 2rem;
}

.resultDate {
  font-size: 0.8rem;
  color: #737373;
}

.resultName {
  color: #292929;
  font-weight: 700;
}

.progressBar {
  height: 14rem;
}

.assesmentHeading {
  font-size: 2rem;
}

.resultMsg {
  margin-top: 1.5rem;
  font-size: 0.9rem;
  letter-spacing: 1px;
  line-height: 1.8rem;
  color: #292929;
}

.CircularProgressbar-path {
  stroke: red;
}

.CircularProgressbar-trail {
  stroke: gray;
}

.CircularProgressbar-text {
  fill: yellow;
}

.CircularProgressbar-background {
  fill: green;
}

.slider-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slider-strip {
  width: 100vw;
  white-space: nowrap;
  -webkit-animation: scroll-left 10s linear infinite;
          animation: scroll-left 10s linear infinite;
}

.slider-strip2 {
  width: 100vw;
  white-space: nowrap;
  -webkit-animation: scroll-stable 15s linear infinite;
          animation: scroll-stable 15s linear infinite;
}

.captionPadding {
  height: 19rem;
}

.arrow {
  -webkit-transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
          transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.arrow:hover {
  opacity: 0;
  -webkit-transform: translate3d(-30px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
          transform: translate3d(-30px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.footerHeading {
  font-size: 1.5rem;
  letter-spacing: 3px;
  line-height: 40px;
  color: #000222;
}

.subFooterHeading {
  font-size: 1.2rem;
  letter-spacing: 1px;
  color: #000222;
  margin-top: 2rem;
}

.ftrLogo {
  height: 3.4rem;
}

.footerText {
  font-size: 1rem;
  letter-spacing: 1px;
  line-height: 30px;
  color: #7a7a7a;
}

.firstBoxText {
  margin-top: 15rem;
  font-size: 16px;
  letter-spacing: 3px;
  line-height: 23px;
  color: #7a7a7a;
  text-align: center;
}

.firstSecondBox {
  margin-top: 18rem;
  padding-left: 2rem;
  font-size: 1.1rem;
  letter-spacing: 1px;
  line-height: 22px;
  color: #000222;
}

.footerLink, .fLink {
  cursor: pointer;
  font-size: 1rem;
  letter-spacing: 1px;
  color: #7a7a7a;
  line-height: 2rem;
  text-decoration: none;
}

.fLink:hover {
  color: #fb2300;
}

.endText {
  font-size: 0.9rem;
  letter-spacing: 1px;
  color: #7a7a7a;
}

.goBckBtn {
  background-color: #fb2300;
  border: none;
  border-radius: 19px;
  padding: 0.5rem 1.5rem;
  color: #ffffff;
}

.goBckBtn:hover {
  background-color: #ca1b00;
}

.sndAns {
  display: flex;
}

.interviewText {
  color: rgb(23, 207, 23);
  font-weight: 600;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 80px;
  height: 80px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #fb2300;
  border-radius: 50%;
  -webkit-animation: spinner 1.5s linear infinite;
          animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  width: 98.5vw;
  position: absolute;
  z-index: 10;
}

.try {
  width: 45%;
}

.date-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18rem;
  width: 93.96vw;
  position: fixed;
  z-index: 1;
}

.date-container:focus .allData {
  -webkit-filter: blur(4px);
          filter: blur(4px);
}

.datePicker {
  width: 50rem;
}

.reasonW {
  width: 30rem;
}

.mmm {
  height: auto;
  padding: 2rem 2rem;
  transition: all .3s ease-in-out;
  background-color: #ffffff;
  border-radius: 2rem;
  box-shadow: 0 0 3px 3px rgb(236, 233, 233);
}

.cancelButton {
  border-radius: 15rem;
  background-color: red;
  color: #ffffff;
  padding: 0 1.2rem;
}

.cancelButton:hover {
  background-color: #ca1b00;
}

.scheduleButton {
  color: #e20808;
  background-color: #f5eded;
  border-radius: 26px;
  border: 1px solid red;
}

.scheduleButton:hover {
  background-color: #ca1b00;
  color: white;
  border: 1px solid red;
}

.scheduleButton:focus {
  color: white;
  border: 1px solid red;
}


@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}


@keyframes scroll-left {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll-stable {
  0% {
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
  }

  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes scroll-stable {
  0% {
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
  }

  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@media only screen and (max-width: 1700px) {
  .signBtn {
    font-size: 1rem;
    margin: 0.3rem;
    padding: 0.3rem 7rem;
  }

  .signBox {
    font-size: 2rem;
    height: 12rem;
  }

  .signText {
    font-size: 2rem;
  }

  .Lheight {
    height: 3rem;
  }
}

a {
  text-decoration: none;
}

@media only screen and (max-width: 1040px) {
  .quizStyle {
    padding: 2rem 3rem;
  }

  .headText {
    margin: 0;
    padding-bottom: 2rem;
  }

  .hdrBtn {
    margin-top: 1rem;
  }

  .try {
    width: 20rem;
  }

  .thankyouImg {
    height: 38vh;
    width: 21rem;
  }

  .mainRow {
    padding: 0 !important;
  }

  .fCol {
    text-align: center;
  }

  .firstBoxText {
    margin-top: 2.8rem !important;
  }

  .firstSecondBox {
    margin: 2rem 0 !important;
    padding: 0 !important;
  }

  .newDisplay {
    padding: 0.6rem 0 !important;
    margin: 0 !important;
    display: block !important;
  }

  .hMargin {
    margin: 0 !important;
  }

  .footerHeading {
    font-size: 1.3rem;
    margin-bottom: 2rem !important;
  }

  .signBtn {
    font-size: 1.2rem;
    padding: 0.3rem 2rem;

  }

  .signBox {
    height: 8.5rem;
  }

  .signText {
    font-size: 1.5rem;
  }

  .Lheight {
    height: 1.8rem;
  }
}

@media only screen and (max-width: 880px) {
  .resLocation {
    height: 2rem;
    width: 1rem;
  }

  .quizImg {
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .resCenter {
    display: none;
  }

  .resPc {
    text-align: center;
    padding-top: 3rem;
  }

  .ansStyle {
    width: auto;
  }

  .sndAns {
    display: block;
  }

  .btnCenter {
    text-align: center;
  }

  .signBtn {
    font-size: 1rem;
  }

  .signBox {
    font-size: 1.5rem;
    height: 8rem;
  }

  .signText {
    font-size: 1.5rem;
  }

  .Lheight {
    height: 1.8rem;
  }

  .linkStyle {
    font-size: 0.8rem;
  }

  .headImg {
    padding-top: 1.4rem;
  }
}

@media only screen and (max-width: 660px) {
  .heading {
    padding-left: 0;
    text-align: center;
  }

  .ftimg {
    display: none;
  }

  .signBtn {
    margin-top: 2rem;
    font-size: 0.8rem;
    padding: 0.4rem 1rem;
    text-align: center;
  }

  .signBox {
    padding: 1rem 1rem 2.5rem !important;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    height: 15rem;
    box-shadow: inset 0px -12px 8px -10px #4b4b4b;
  }

  .signText {
    line-height: 40px;
  }

  .gLogo {
    margin-right: 0.3rem;
    padding-bottom: 0.3rem;
  }

  .Lheight {
    height: 1.5rem;
  }

  .registaerHeading {
    font-size: 2rem;
  }

  .txt {
    text-align: left;
    padding-right: 0;
  }

  .resBtn {
    padding-top: 0.4rem;
    height: 3.5rem;
  }

  .timeStyle {
    font-size: 1rem !important;
    margin-right: 0.4rem;
  }

  .quesStlyle {
    font-size: 0.8rem;
  }

  .firstSecondBox {
    font-size: 1rem;
  }

  .scIcn {
    height: 1.4rem;
  }

  .ansStyle {
    font-size: 0.8rem;
  }

  .mobileJobProfile {
    margin-top: 1rem !important;
  }

  .heading {
    margin-top: 1rem;
    padding: 1rem;
  }

  .navFieldDiv {
    top: 4.6rem;
    right: .3rem;
    width: 100vw;
    background-color: #ffffff;
  }

  .congText {
  font-size: 1.5rem;
  font-weight: 700;
}
.congText2 {
  font-size: 1rem;
  font-weight: 700;
}

.jobPrfl{
  font-size: 0.89rem !important;
}

}

@media only screen and (max-width: 450px) {
  .registaerHeading {
    font-size: 1.5rem;
    line-height: 34px;
  }

  .registerForm {
    margin-top: 2rem !important;
  }

  .signBtn {
    font-size: 0.8rem;
    padding: 0.4rem 0.5rem;
  }

  .linkStyle {
    font-size: 0.6rem;
  }

  .quizStyle {
    padding: 1.7rem;
  }

  .logoStyle {
    max-width: 85%;
    margin: 0.8rem 0.3rem !important;
  }

  .quesStlyle {
    text-transform: uppercase !important;
  }

  .headText {
    font-size: 1.5rem;
    line-height: 45px;
  }

  .captionPadding {
    height: 14rem;
  }

  .qResult {
    padding: 0.3rem 2rem
  }

  .navButton {
    font-size: 14px;
  }

  .navField {
    font-size: 14px;
  }

  .navFieldDiv {
    top: 4.1rem;
  }

}

@media only screen and (max-width: 360px) {
  .ftrLogo {
    height: 3rem;
  }

  .navButton {
    font-size: 12px;
    padding: 0.5rem 1rem !important;
  }

  .navFieldDiv {
    top: 3.5rem;
  }

  .navField {
    font-size: 12px;
    width: 7rem;
  }
}
